<script setup lang="ts">
import { ref } from "vue"
import { useTheme } from "@/commons/composables/use-theme"
import { DashboardRoutesDefinitions } from "@/dashboard/routes"
import { FAQRoutesDefinitions } from "@/faq/routes"
import { ManagementRoutesDefinitions } from "@/management/routes"
import { useCart } from "@/marketplace/composables/use-cart"
import { MarketplaceRoutesDefinitions } from "@/marketplace/routes"
import TheNotificationsSidebar from "@/notifications/components/TheNotificationsSidebar.vue"
import { useNotificationsStore } from "@/notifications/composables/use-notifications"
import { useUserStore } from "@/organization/composables/use-user"
import UiDivider from "@/ui/atoms/UiDivider.vue"
import SidebarMenuItem from "./SidebarMenuItem.vue"
import SidebarMenuUser from "./SidebarMenuUser.vue"

const user = useUserStore()

const cartStore = useCart()

const notificationsStore = useNotificationsStore()
const shouldShowNotificationSidebar = ref(false)

const onItemClick = (actionFn = () => {}) => {
  actionFn()
  shouldShowNotificationSidebar.value = false
}

const { getLogo } = useTheme()
</script>

<script lang="ts">
// This is used to add required margin to the notifications sidebar. Please keep the values in sync
export const SIDEBAR_CLASSES = {
  width: "w-24", // 24 = 6rem
  notificationsSidebar: "!left-24 z-1",
  siteContent: "ml-24 w-[calc(100%-6rem)]",
}
</script>

<template>
  <aside
    class="z-1000 h-100vh fixed grid grid-rows-[auto_1fr_auto] bg-$color-dark text-light-900"
    :class="SIDEBAR_CLASSES.width"
  >
    <!-- Logo -->
    <section class="flex pt-8 pb-16">
      <img class="m-auto" height="50" :src="getLogo()" />
    </section>
    <!-- Navigation -->
    <section class="overflow-auto grid content-between">
      <ul>
        <li>
          <sidebar-menu-item
            title="Dashboard"
            icon="i-mdi-home-variant-outline"
            :to="DashboardRoutesDefinitions.DASHBOARD"
            @click="onItemClick"
          />
        </li>
        <li>
          <sidebar-menu-item
            title="Projects"
            icon="i-mdi-finance"
            :to="MarketplaceRoutesDefinitions.PROJECT_LIST_ROOT"
            @click="onItemClick"
          />
        </li>
      </ul>
      <ul>
        <li v-if="user.hasPermission('PURCHASE')">
          <sidebar-menu-item
            title="Your Cart"
            icon="i-mdi-cart-outline"
            :badge="cartStore.cart.items.length"
            :to="MarketplaceRoutesDefinitions.CART"
            @click="onItemClick"
          />
        </li>
        <li>
          <sidebar-menu-item
            title="Notifications"
            icon="i-mdi-bell-outline"
            :badge="notificationsStore.unreadNotifications.length"
            @click="shouldShowNotificationSidebar = !shouldShowNotificationSidebar"
          />
        </li>
        <li>
          <sidebar-menu-item
            title="Support"
            icon="i-mdi-help-circle-outline"
            :to="FAQRoutesDefinitions.FAQ"
            @click="onItemClick"
          />
        </li>
        <li>
          <sidebar-menu-item
            v-if="user.isAdmin"
            title="Management"
            icon="i-mdi-store-cog"
            :to="ManagementRoutesDefinitions.MANAGEMENT"
            @click="onItemClick"
          />
        </li>
      </ul>
    </section>
    <!-- User -->
    <section class="m-auto">
      <ui-divider class="m-0 opacity-15" />
      <sidebar-menu-user @click:item="onItemClick" />
    </section>
  </aside>
  <the-notifications-sidebar
    id="notification-sidebar"
    v-model:visible="shouldShowNotificationSidebar"
  />
</template>

<style scoped lang="scss"></style>
